<template>
  <div>
    <el-card class="box-card">
      <span class="titletext">产品菜单</span>
      <el-row>
        <el-col :span="12"><div class="grid-content bg-purple">
          <span class="titletext">中文</span>
           <el-table :data="MenunsCn" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Menus" label="名称" align="center"></el-table-column>
          <el-table-column align="center">
          <template slot="header">
            <el-button type="primary" @click="menusvisiblecn = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="menusxgcn(scope.row)" size="small">修改</el-button>
            <el-button type="primary" @click="productaddcn(scope.row)" size="small">添加产品</el-button>
            <el-button type="danger"  @click="menusdelcn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
        <el-col :span="12"><div class="grid-content bg-purple-light">
          <span class="titletext">英文</span>
           <el-table :data="MenunsEn" border style="width: 100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Menus" label="名称" align="center"></el-table-column>
          <el-table-column align="center">
          <template slot="header">
            <el-button type="primary" @click="menusvisibleen = true">新增<i class="el-icon-upload el-icon--right"></i></el-button>
          </template>
          <template slot-scope="scope">
            <el-button type="primary" @click="menusxgen(scope.row)" size="small">修改</el-button>
            <el-button type="primary" @click="productadden(scope.row)" size="small">添加产品</el-button>
            <el-button type="danger" @click="menusdelen(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
          </div></el-col>
      </el-row>
    </el-card>
    <el-dialog title="新增中文菜单" :modal-append-to-body='false' :visible.sync="menusvisiblecn" width="500px">
      <span class="titletext">增加文本</span>
      <el-input v-model="menustext" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menusvisiblecn = false">取 消</el-button>
        <el-button type="primary" @click="menusaddcn">确 定</el-button>
      </div>
    </el-dialog>
   <el-dialog title="新增英文菜单" :modal-append-to-body='false' :visible.sync="menusvisibleen" width="500px">
      <span class="titletext">增加文本</span>
    <el-input v-model="menustext" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="menusvisibleen = false">取 消</el-button>
        <el-button type="primary" @click="menusadden">确 定</el-button>
      </div>
    </el-dialog>
    <!---中文修改ul-->
    <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="menusxgvisvscn" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改文本">
          <el-input v-model="dialog.Menus" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="menusxgvisvscn = false">取 消</el-button>
      <el-button @click="xgmenuscn(dialog)" type="primary">确 定</el-button>
    </div>
  </el-dialog>
  <!---英文修改ul-->
  <el-dialog title="提示" :modal-append-to-body='false' :visible.sync="menusxgvisvsen" width="500px">
      <el-form :model="dialog">
        <el-form-item label="修改文本">
          <el-input v-model="dialog.Menus" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="menusxgvisvsen = false">取 消</el-button>
      <el-button @click="xgmenusen(dialog)" type="primary">确 定</el-button>
    </div>
  </el-dialog>
  <!---添加中文信息-->
  <el-dialog title="添加中文产品" :modal-append-to-body='false' :visible.sync="productvisviblecn" width="80%">
    <el-form :model="form">
      <el-form-item label="List图片">
        <el-upload class="upload-demo" style="text-align: center;" ref="producimg1cn" action="" drag :limit="1"
          :http-request="producimg1cn" :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情大图">
        <el-upload class="upload-demo" style="text-align: center;" ref="producimg2cn" action="" drag :limit="1"
          :http-request="producimg2cn" :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="List时间">
        <el-date-picker v-model="form.Datetext" value-format="yyyy-mm-dd HH:mm:ss" type="date" placeholder="选择日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="List标题">
        <el-input v-model="form.H5text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="文本">
        <el-input type="textarea" :rows="10" v-model="form.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="productvisviblecn = false">取 消</el-button>
      <el-button @click="productxgformcn(form)"  type="primary">确 定</el-button>
    </div>
  </el-dialog>
  <!---添加英文信息-->
  <el-dialog title="添加中文产品" :modal-append-to-body='false' :visible.sync="productvisvibleen" width="80%">
    <el-form :model="form">
      <el-form-item label="List图片">
        <el-input v-model="form.Imgsrc" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="详情大图">
        <el-input v-model="form.Imgsrc_1" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="List时间">
        <el-date-picker v-model="form.Datetext" value-format="yyyy-mm-dd HH:mm:ss" type="date" placeholder="选择日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="List标题">
        <el-input v-model="form.H5text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="文本">
        <el-input type="textarea" :rows="10" v-model="form.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="productvisvibleen = false">取 消</el-button>
      <el-button @click="productxgformen(form)"  type="primary">确 定</el-button>
    </div>
  </el-dialog>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">中文信息列表</span>
      <el-row class="adminprod">
          <el-table :data="NewsCn" border style="width:100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Imgsrc" label="List图名" align="center"></el-table-column>
          <el-table-column  label="详细大图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Datetext" label="List图名" align="center"></el-table-column>
          <el-table-column prop="H5text" label="List图名" align="center"></el-table-column>
          <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="xgprodunccn(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="productdelcn(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-row>
      <div class="container">
        <div class="pagination">
            <!---->
            <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="queryInfo1.pagenum"
                :page-sizes="[12, 15, 21, 30]"
                :page-size="queryInfo1.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total1">
            </el-pagination>
        </div>
    </div>
    </el-card>
    <!---修改中文信息-->
  <el-dialog title="修改中文信息" :modal-append-to-body='false' :visible.sync="newvisviblecn" width="80%">
    <el-form :model="form">
      <el-form-item label="List图片">
        <el-input v-model="form.Imgsrc" disabled autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="List图片">
        <el-upload class="upload-demo" style="text-align: center;" ref="producimg1cn" action="" drag :limit="1"
          :http-request="producimg1cn" :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情大图">
        <el-input v-model="formdata.Imgsrc" disabled autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="详情大图">
        <el-upload class="upload-demo" style="text-align: center;" ref="producimg2cn" action="" drag :limit="1"
          :http-request="producimg2cn" :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="List时间">
        <el-date-picker v-model="form.Datetext" value-format="yyyy-mm-dd HH:mm:ss" type="date" placeholder="选择日期"> </el-date-picker>
      </el-form-item>
      <el-form-item label="List标题">
        <el-input v-model="form.H5text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="文本">
        <el-input type="textarea" :rows="10" v-model="formdata.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="newvisviblecn = false">取 消</el-button>
      <el-button @click="newsgxcn(form)"  type="primary">确 定</el-button>
    </div>
  </el-dialog>
      <!---修改英文信息-->
      <el-dialog title="修改英文信息" :modal-append-to-body='false' :visible.sync="newvisvibleen" width="80%">
        <el-form :model="form">
          <el-form-item label="List图片">
            <el-input v-model="form.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="详情大图">
            <el-input v-model="formdata.Imgsrc" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="List时间">
            <el-date-picker v-model="form.Datetext" value-format="yyyy-mm-dd HH:mm:ss" type="date" placeholder="选择日期"> </el-date-picker>
          </el-form-item>
          <el-form-item label="List标题">
            <el-input v-model="form.H5text" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="文本">
            <el-input type="textarea" :rows="10" v-model="formdata.Text" placeholder="可做段落分割，P标签，br标签都可使用" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="newvisvibleen = false">取 消</el-button>
          <el-button @click="newsgxen(form)"  type="primary">确 定</el-button>
        </div>
      </el-dialog>
    <hr class="w-100 mt-5 mb-5" />
    <el-card class="box-card">
      <span class="titletext">英文信息列表</span>
      <el-row class="adminprod">
        <el-table :data="NewsEn" border style="width:100%; margin-bottom: auto; margin: auto;">
          <el-table-column type="index" width="50" align="center"> </el-table-column>
          <el-table-column prop="Imgsrc" label="List图名" align="center"></el-table-column>
          <el-table-column  label="详细大图" align="center">
            <template slot-scope="scope">
                  <el-image :src="`${$FilesImg}${scope.row.Imgsrc}`">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
          </el-table-column>
          <el-table-column prop="Datetext" label="List图名" align="center"></el-table-column>
          <el-table-column prop="H5text" label="List图名" align="center"></el-table-column>
          <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="xgproduncen(scope.row)" size="small">修改</el-button>
            <el-button type="danger" @click="productdelen(scope.row.Id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-row>
      <div class="container">
        <div class="pagination">
            <!---->
            <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="queryInfo2.pagenum"
                :page-sizes="[12, 15, 21, 30]"
                :page-size="queryInfo2.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total2">
            </el-pagination>
        </div>
    </div>
  </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      MenunsCn: [],
      MenunsEn: [],
      total1: 0,
      total2: 0,
      queryInfo1: {
        Id: 0,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      },
      queryInfo2: {
        Id: 0,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      },
      NewsCn: [],
      NewsEn: [],
      menustext: '',
      menusvisiblecn: false,
      menusvisibleen: false,
      menusxgvisvscn: false,
      menusxgvisvsen: false,
      dialog: [],
      productvisviblecn: false,
      productvisvibleen: false,
      productimgfile2cn: '',
      productimgfile1cn: '',
      form: {
        Id: '',
        Cid: '',
        Imgsrc: '',
        Imgsrc_1: '',
        Datetext: '',
        H5text: '',
        Text: ''
      },
      newvisviblecn: false,
      newvisvibleen: false,
      formdata: {
        Id: '',
        Cid: '',
        Imgsrc: '',
        Text: ''
      }
    }
  },
  created () {
    this.News_MenunsCn()
    this.News_MenunsEn()
    this.News_dataCn()
    this.News_dataEn()
  },
  mounted () {

  },
  methods: {
    async News_MenunsCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}News_Menuns`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.MenunsCn = res.response.Menuns
    },
    async News_MenunsEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}News_Menuns`)
      if (res.status !== 200) return this.$message.error('数据异常')
      this.MenunsEn = res.response.Menuns
    },
    async News_dataCn () {
      const { data: res } = await this.$http.get(`${this.$httpcn}News`, { params: this.queryInfo1 })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.NewsCn = res.response.News_list1
      this.total1 = res.response.Total
    },
    async News_dataEn () {
      const { data: res } = await this.$http.get(`${this.$httpen}News`, { params: this.queryInfo2 })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.NewsEn = res.response.News_list1
      this.total2 = res.response.Total
    },
    // 监听pagesize的改变事件
    handleSizeChange1 (newSize) {
      this.queryInfo1.Pagesize = newSize
      this.News_dataCn()
    },
    // 监听页面值的改变事件
    handleCurrentChange1 (newPage) {
      this.queryInfo1.Pagenum = newPage
      this.News_dataCn()
    },
    // 监听pagesize的改变事件
    handleSizeChange2 (newSize) {
      this.queryInfo2.Pagesize = newSize
      this.News_dataEn()
    },
    // 监听页面值的改变事件
    handleCurrentChange2 (newPage) {
      this.queryInfo2.Pagenum = newPage
      this.News_dataEn()
    },
    async menusaddcn () {
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusaddcn?Text=${this.menustext}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menustext = ''
      this.menusvisiblecn = false
      this.$message.success('添加成功')
      this.News_MenunsCn()
    },
    async menusadden () {
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusadden?Text=${this.menustext}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menustext = ''
      this.menusvisibleen = false
      this.$message.success('添加成功')
      this.News_MenunsEn()
    },
    async xgmenuscn (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Menus
      }
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusxgcn`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menusxgvisvscn = false
      this.$message.success('修改成功')
      this.News_MenunsCn()
    },
    async xgmenusen (row) {
      var Capabilitydata = {
        Id: row.Id,
        Text: row.Menus
      }
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusxgen`, Capabilitydata)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.menusxgvisvsen = false
      this.$message.success('修改成功')
      this.News_MenunsEn()
    },
    menusxgcn (row) {
      this.dialog = row
      this.menusxgvisvscn = true
    },
    menusxgen (row) {
      this.dialog = row
      this.menusxgvisvsen = true
    },
    async menusdelcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusdelcn?Id=${row}`)
      if (res.status === 201) return this.$message.success(res.msg)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.News_MenunsCn()
      this.$message.success('删除成功')
    },
    async menusdelen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}News_menusdelen?Id=${row}`)
      if (res.status === 201) return this.$message.success(res.msg)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.News_MenunsEn()
      this.$message.success('删除成功')
    },
    producimg2cn (param) {
      this.productimgfile2cn = param.file
    },
    producimg1cn (param) {
      this.productimgfile1cn = param.file
    },
    productaddcn (row) {
      this.form = row
      this.productvisviblecn = true
    },
    productadden (row) {
      this.form = row
      this.productvisvibleen = true
    },
    async productxgformcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.producimg1cn.submit()
      this.$refs.producimg2cn.submit()
      var File = new FormData()
      File.append('Cid', row.Id)
      File.append('Img1', this.productimgfile1cn)
      File.append('Img2', this.productimgfile2cn)
      File.append('H5text', row.H5text)
      File.append('Text', row.Text)
      File.append('Datetext', row.Datetext)
      const { data: res } = await this.$http.post(`${this.$admin}News_productaddcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productimgfile1cn = ''
      this.productimgfile2cn = ''
      this.productvisviblecn = false
      this.$refs.producimg1cn.clearFiles()
      this.$refs.producimg2cn.clearFiles()
      this.News_dataCn()
    },
    async productxgformen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Cid', row.Id)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc_1', row.Imgsrc_1)
      File.append('H5text', row.H5text)
      File.append('Text', row.Text)
      File.append('Datetext', row.Datetext)
      const { data: res } = await this.$http.post(`${this.$admin}News_productadden`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productvisvibleen = false
      this.News_dataEn()
    },
    async productdelcn (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}News_listdelcn?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.News_dataCn()
      this.$message.success('删除成功')
    },
    async productdelen (row) {
      const { data: res } = await this.$axios.post(`${this.$admin}News_listdelen?Id=${row}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.News_dataEn()
      this.$message.success('删除成功')
    },
    async xgprodunccn (row) {
      this.form = row
      this.newvisviblecn = true
      const { data: res } = await this.$axios.post(`${this.$admin}News_listdatacn?Id=${row.Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.formdata = res.response
    },
    async xgproduncen (row) {
      this.form = row
      this.newvisvibleen = true
      const { data: res } = await this.$axios.post(`${this.$admin}News_listdataen?Id=${row.Id}`)
      if (res.status !== 200) return this.$message.success('访问异常')
      this.formdata = res.response
    },
    async newsgxcn (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$refs.producimg1cn.submit()
      this.$refs.producimg2cn.submit()
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Cid', row.Cid)
      File.append('Img1', this.productimgfile1cn)
      File.append('Img2', this.productimgfile2cn)
      File.append('H5text', row.H5text)
      File.append('Text', this.formdata.Text)
      File.append('Datetext', row.Datetext)
      const { data: res } = await this.$http.post(`${this.$admin}News_productxgcn`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.productimgfile1cn = ''
      this.productimgfile2cn = ''
      this.newvisviblecn = false
      this.$refs.producimg1cn.clearFiles()
      this.$refs.producimg2cn.clearFiles()
      this.News_dataCn()
    },
    async newsgxen (row) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      var File = new FormData()
      File.append('Id', row.Id)
      File.append('Cid', row.Cid)
      File.append('Imgsrc', row.Imgsrc)
      File.append('Imgsrc_1', this.formdata.Imgsrc)
      File.append('H5text', row.H5text)
      File.append('Text', this.formdata.Text)
      File.append('Datetext', row.Datetext)
      const { data: res } = await this.$http.post(`${this.$admin}News_productxgen`, File, config.headers)
      if (res.status !== 200) return alert('上传失败')
      this.$message.success('上传成功')
      this.newvisvibleen = false
      this.News_dataEn()
    }
  },
  onSubmit () {

  }
}
</script>
<style lang="less" scoped>
  .el-card {
    margin: 0 auto;
    width: 95%;
  }
  .el-image{
    width: 360px;
  }
  .el-form{
    margin: 0 auto;
    width: 80%;
  }
  .el-row{
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
  }
  .titletext{
    font-size: 20px;
    color: rgb(8, 152, 248);
  }
</style>
