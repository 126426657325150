<template>
  <div>
      <section data-overlay="6" class="cover imagebg text-left space--sm">
          <div class="background-image-holder"
              :style="`background: url(${$FilesImg}${About_data.Backgroundimg1}); opacity: 1;`"><img
                  alt="People in different ethnicity giving each other a high five" :src="`${$FilesImg}${About_data.backgroundimg1}`"></div>
          <div class="container">
              <div class="row">
                  <div class="col-md-8 col-lg-7">
                      <h1 v-html="About_data.Htitle"></h1>
                      <p v-html="About_data.Htext"></p>
                  </div>
              </div>
          </div>
      </section>
      <section class="py-5 breadcrumbsSection houjia">
          <div class="container">
              <ol class="breadcrumbs">
                  <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                  <li>{{this.$t('Pathyem.guanyu')}}</li>
              </ol>
          </div>
      </section>
      <section class="houjia">
          <div class="container">
              <div class="row justify-content-start">
                  <div class="col-12">
                      <div class="switchable__text">
                          <h2 class="h2" v-html="About_data.Title"></h2>
                          <hr class="short">
                          <p class="sub-lead" v-html="About_data.P1text"></p>
                          <p class="sub-lead" v-html="About_data.P2text"></p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="text-center bg--secondary houjia">
          <div class="container">
              <div class="row">
                  <div v-for="(itme,index) in About_data.About_list1" :key="index" class="col-md-6">
                      <div class="feature feature-3 boxed boxed--lg boxed--border"><img :src="`${$FilesImg}${itme.Imgsrc}`">
                          <h4 v-html="itme.Text"></h4>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="houjia">
          <div class="container">
              <h3 class="text-center" v-html="About_data.List1_title"></h3>
              <div class="row justify-content-center">
                  <div class="col-lg-8">
                      <div class="process-1">
                          <div v-for="(itme,index) in About_data.About_list2" :key="index" class="process__item">
                              <h4 class="h4 mb-0" v-html="itme.Datetitle"></h4>
                              <p class="sub-lead" v-html="itme.Text"></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="bg--secondary houjia">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <h3 class="text-left" v-html="About_data.List2_title"></h3>
                      <p v-html="About_data.List2_p"></p>
                  </div>
              </div>
          </div>
          <div class="container mt-5">
              <div v-for="(itme,index) in About_data.About_listtitle" :key="index" style="border-bottom: 2px solid rgb(185 172 172 / 42%);"  class="row  py-3">
                  <div class="col-lg-4">
                      <div class="text-block py-1 mb-0">
                          <h4 class="font-weight-bold mb-0" v-html="itme.Title"></h4>
                      </div>
                  </div>
                  <div class="col-lg-8">
                      <div class="row flex-wrap">
                          <div v-for="(itmee,indexx) in itme.About_list3" :key="indexx"  class="col-12 col-sm-6 py-1">
                              <p class="mb-0" v-html="itmee.Text"></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="houjia">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <h3 v-html="About_data.About_forr.Forr_h3"></h3>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="row justify-content-around">
                  <div class="col-md-6 pl-sd-0 py-3"><img :src="`${$FilesImg}${About_data.About_forr.Imgsrc}`"
                          class="border--round box-shadow-wide"></div>
                  <div class="col-md-6 col-lg-5 py-3">
                      <div class="switchable__text">
                          <h4 v-html="About_data.About_forr.Forr_h4"></h4>
                          <p v-html="About_data.About_forr.Forr_p1"></p>
                      </div>
                      <div class="switchable__text mt-5">
                          <h4 v-html="About_data.About_forr.Foor_h41"></h4>
                          <p v-html="About_data.About_forr.Foor_p2">
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <div>
         <Foorm></Foorm>
      </div>
  </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      About_data: {
        Title: '',
        P1text: '',
        P2text: '',
        List1_title: '',
        List2_title: '',
        List2_p: '',
        List3_title: '',
        About_list1: [],
        About_list2: [],
        About_list3: [],
        About_listtitle: [],
        About_forr: {
          Imgsrc: '',
          Forr_h3: '',
          Forr_h4: '',
          Forr_p1: '',
          Foor_h41: '',
          Foor_p2: ''
        }
      }

    }
  },
  created () {
    this.About()
  },
  mounted () {

  },
  components: {
    Foorm
  },
  methods: {
    async About () {
      const { data: res } = await this.$http.get('About')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.About_data = res.response
    }
  }
}
</script>
<style lang="less" scoped>
.houjia {
    padding-top: 5.57142857em;
    padding-bottom: 5.57142857em;
}
</style>
