<template>
    <div>
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder"
                :style="`background: url(${$FilesImg}banner-investors.jpg); opacity: 1;`">
                <img alt="" :src="`${$FilesImg}banner-investors.jpg`">
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1>{{ $t('lianxi.联络与洽询') }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{ $t('lianxi.首页') }}</a></li>
                    <li>{{ $t('lianxi.联络与洽询') }}</li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <form class="text-left">
                    <div class="row">
                        <div class="col-md-12 mb-3"><label>{{$t('lianxi.xm')}}</label>
                            <div class="input-select">
                                <select id="inquerySelect" autofocus="autofocus" v-model="Contact_data.Xml" class="validate-required">
                                    <option disabled="disabled" selected="selected">{{$t('lianxi.请选择')}}</option>
                                    <option value="产品相关">{{ this.$t('lianxi.产品相关') }}</option>
                                    <option value="解决方案相关">{{ this.$t('lianxi.解决方案相关')}}</option>
                                    <option value="品牌代理">{{ this.$t('lianxi.品牌代理') }}</option>
                                    <option value="其他">{{ this.$t('lianxi.其他')}}</option>
                                </select></div>
                        </div>
                    </div>
                    <!---->
                    <!---->
                    <div class="row">
                        <div class="col-md-6"><label>{{$t('lianxi.gj')}}</label>
                            <div class="input-select">
                                <select v-model="Contact_data.Gj">
                                    <option disabled="disabled" selected="selected">{{$t('lianxi.请选择')}}</option>
                                    <option>Afghanistan</option>
                                    <option>Åland Islands</option>
                                    <option>Albania</option>
                                    <option>Algeria</option>
                                    <option>American Samoa</option>
                                    <option>Andorra</option>
                                    <option>Angola</option>
                                    <option>Anguilla</option>
                                    <option>Antarctica</option>
                                    <option>Antigua and Barbuda</option>
                                    <option>Argentina</option>
                                    <option>Armenia</option>
                                    <option>Aruba</option>
                                    <option>Australia</option>
                                    <option>Austria</option>
                                    <option>Azerbaijan</option>
                                    <option>Bahamas</option>
                                    <option>Bahrain</option>
                                    <option>Bangladesh</option>
                                    <option>Barbados</option>
                                    <option>Belarus</option>
                                    <option>Belgium</option>
                                    <option>Belize</option>
                                    <option>Benin</option>
                                    <option>Bermuda</option>
                                    <option>Bhutan</option>
                                    <option>Bolivia (Plurinational State of)</option>
                                    <option>Bonaire, Sint Eustatius and Saba</option>
                                    <option>Bosnia and Herzegovina</option>
                                    <option>Botswana</option>
                                    <option>Bouvet Island</option>
                                    <option>Brazil</option>
                                    <option>British Indian Ocean Territory</option>
                                    <option>United States Minor Outlying Islands</option>
                                    <option>Virgin Islands (British)</option>
                                    <option>Virgin Islands (U.S.)</option>
                                    <option>Brunei Darussalam</option>
                                    <option>Bulgaria</option>
                                    <option>Burkina Faso</option>
                                    <option>Burundi</option>
                                    <option>Cambodia</option>
                                    <option>Cameroon</option>
                                    <option>Canada</option>
                                    <option>Cabo Verde</option>
                                    <option>Cayman Islands</option>
                                    <option>Central African Republic</option>
                                    <option>Chad</option>
                                    <option>Chile</option>
                                    <option>China</option>
                                    <option>Christmas Island</option>
                                    <option>Cocos (Keeling) Islands</option>
                                    <option>Colombia</option>
                                    <option>Comoros</option>
                                    <option>Congo</option>
                                    <option>Congo (Democratic Republic of the)</option>
                                    <option>Cook Islands</option>
                                    <option>Costa Rica</option>
                                    <option>Croatia</option>
                                    <option>Cuba</option>
                                    <option>Curaçao</option>
                                    <option>Cyprus</option>
                                    <option>Czech Republic</option>
                                    <option>Denmark</option>
                                    <option>Djibouti</option>
                                    <option>Dominica</option>
                                    <option>Dominican Republic</option>
                                    <option>Ecuador</option>
                                    <option>Egypt</option>
                                    <option>El Salvador</option>
                                    <option>Equatorial Guinea</option>
                                    <option>Eritrea</option>
                                    <option>Estonia</option>
                                    <option>Ethiopia</option>
                                    <option>Falkland Islands (Malvinas)</option>
                                    <option>Faroe Islands</option>
                                    <option>Fiji</option>
                                    <option>Finland</option>
                                    <option>France</option>
                                    <option>French Guiana</option>
                                    <option>French Polynesia</option>
                                    <option>French Southern Territories</option>
                                    <option>Gabon</option>
                                    <option>Gambia</option>
                                    <option>Georgia</option>
                                    <option>Germany</option>
                                    <option>Ghana</option>
                                    <option>Gibraltar</option>
                                    <option>Greece</option>
                                    <option>Greenland</option>
                                    <option>Grenada</option>
                                    <option>Guadeloupe</option>
                                    <option>Guam</option>
                                    <option>Guatemala</option>
                                    <option>Guernsey</option>
                                    <option>Guinea</option>
                                    <option>Guinea-Bissau</option>
                                    <option>Guyana</option>
                                    <option>Haiti</option>
                                    <option>Heard Island and McDonald Islands</option>
                                    <option>Holy See</option>
                                    <option>Honduras</option>
                                    <option>Hong Kong</option>
                                    <option>Hungary</option>
                                    <option>Iceland</option>
                                    <option>India</option>
                                    <option>Indonesia</option>
                                    <option>Côte d'Ivoire</option>
                                    <option>Iran (Islamic Republic of)</option>
                                    <option>Iraq</option>
                                    <option>Ireland</option>
                                    <option>Isle of Man</option>
                                    <option>Israel</option>
                                    <option>Italy</option>
                                    <option>Jamaica</option>
                                    <option>Japan</option>
                                    <option>Jersey</option>
                                    <option>Jordan</option>
                                    <option>Kazakhstan</option>
                                    <option>Kenya</option>
                                    <option>Kiribati</option>
                                    <option>Kuwait</option>
                                    <option>Kyrgyzstan</option>
                                    <option>Lao People's Democratic Republic</option>
                                    <option>Latvia</option>
                                    <option>Lebanon</option>
                                    <option>Lesotho</option>
                                    <option>Liberia</option>
                                    <option>Libya</option>
                                    <option>Liechtenstein</option>
                                    <option>Lithuania</option>
                                    <option>Luxembourg</option>
                                    <option>Macao</option>
                                    <option>Macedonia (the former Yugoslav Republic of)</option>
                                    <option>Madagascar</option>
                                    <option>Malawi</option>
                                    <option>Malaysia</option>
                                    <option>Maldives</option>
                                    <option>Mali</option>
                                    <option>Malta</option>
                                    <option>Marshall Islands</option>
                                    <option>Martinique</option>
                                    <option>Mauritania</option>
                                    <option>Mauritius</option>
                                    <option>Mayotte</option>
                                    <option>Mexico</option>
                                    <option>Micronesia (Federated States of)</option>
                                    <option>Moldova (Republic of)</option>
                                    <option>Monaco</option>
                                    <option>Mongolia</option>
                                    <option>Montenegro</option>
                                    <option>Montserrat</option>
                                    <option>Morocco</option>
                                    <option>Mozambique</option>
                                    <option>Myanmar</option>
                                    <option>Namibia</option>
                                    <option>Nauru</option>
                                    <option>Nepal</option>
                                    <option>Netherlands</option>
                                    <option>New Caledonia</option>
                                    <option>New Zealand</option>
                                    <option>Nicaragua</option>
                                    <option>Niger</option>
                                    <option>Nigeria</option>
                                    <option>Niue</option>
                                    <option>Norfolk Island</option>
                                    <option>Korea (Democratic People's Republic of)</option>
                                    <option>Northern Mariana Islands</option>
                                    <option>Norway</option>
                                    <option>Oman</option>
                                    <option>Pakistan</option>
                                    <option>Palau</option>
                                    <option>Palestine, State of</option>
                                    <option>Panama</option>
                                    <option>Papua New Guinea</option>
                                    <option>Paraguay</option>
                                    <option>Peru</option>
                                    <option>Philippines</option>
                                    <option>Pitcairn</option>
                                    <option>Poland</option>
                                    <option>Portugal</option>
                                    <option>Puerto Rico</option>
                                    <option>Qatar</option>
                                    <option>Republic of Kosovo</option>
                                    <option>Réunion</option>
                                    <option>Romania</option>
                                    <option>Russian Federation</option>
                                    <option>Rwanda</option>
                                    <option>Saint Barthélemy</option>
                                    <option>Saint Helena, Ascension and Tristan da Cunha</option>
                                    <option>Saint Kitts and Nevis</option>
                                    <option>Saint Lucia</option>
                                    <option>Saint Martin (French part)</option>
                                    <option>Saint Pierre and Miquelon</option>
                                    <option>Saint Vincent and the Grenadines</option>
                                    <option>Samoa</option>
                                    <option>San Marino</option>
                                    <option>Sao Tome and Principe</option>
                                    <option>Saudi Arabia</option>
                                    <option>Senegal</option>
                                    <option>Serbia</option>
                                    <option>Seychelles</option>
                                    <option>Sierra Leone</option>
                                    <option>Singapore</option>
                                    <option>Sint Maarten (Dutch part)</option>
                                    <option>Slovakia</option>
                                    <option>Slovenia</option>
                                    <option>Solomon Islands</option>
                                    <option>Somalia</option>
                                    <option>South Africa</option>
                                    <option>South Georgia and the South Sandwich Islands</option>
                                    <option>Korea (Republic of)</option>
                                    <option>South Sudan</option>
                                    <option>Spain</option>
                                    <option>Sri Lanka</option>
                                    <option>Sudan</option>
                                    <option>Suriname</option>
                                    <option>Svalbard and Jan Mayen</option>
                                    <option>Swaziland</option>
                                    <option>Sweden</option>
                                    <option>Switzerland</option>
                                    <option>Syrian Arab Republic</option>
                                    <option>Taiwan</option>
                                    <option>Tajikistan</option>
                                    <option>Tanzania, United Republic of</option>
                                    <option>Thailand</option>
                                    <option>Timor-Leste</option>
                                    <option>Togo</option>
                                    <option>Tokelau</option>
                                    <option>Tonga</option>
                                    <option>Trinidad and Tobago</option>
                                    <option>Tunisia</option>
                                    <option>Turkey</option>
                                    <option>Turkmenistan</option>
                                    <option>Turks and Caicos Islands</option>
                                    <option>Tuvalu</option>
                                    <option>Uganda</option>
                                    <option>Ukraine</option>
                                    <option>United Arab Emirates</option>
                                    <option>United Kingdom of Great Britain and Northern Ireland</option>
                                    <option>United States of America</option>
                                    <option>Uruguay</option>
                                    <option>Uzbekistan</option>
                                    <option>Vanuatu</option>
                                    <option>Venezuela (Bolivarian Republic of)</option>
                                    <option>Viet Nam</option>
                                    <option>Wallis and Futuna</option>
                                    <option>Western Sahara</option>
                                    <option>Yemen</option>
                                    <option>Zambia</option>
                                    <option>Zimbabwe</option>
                                </select></div>
                        </div>
                        <div class="col-md-6"><label>{{$t('lianxi.cs')}}</label><input type="text" v-model="Contact_data.ChengS" name="city"></div>
                        <div class="col-md-6"><label>{{$t('lianxi.gs')}}</label><input type="text" v-model="Contact_data.Gsname" name="company"></div>
                        <div class="col-md-6"><label>{{$t('lianxi.name')}}</label><input type="text" v-model="Contact_data.Name" name="name"></div>
                        <div class="col-md-6"><label>{{$t('lianxi.mail')}}</label><input type="email" v-model="Contact_data.Email" name="email"></div>
                        <div class="col-md-6"><label>{{$t('lianxi.iPhone')}}</label><input type="text" v-model="Contact_data.Ipone" name="tel"></div>
                        <div class="col-md-12"><label>{{$t('lianxi.td')}}</label>
                            <div class="input-select"><select  v-model="Contact_data.Qdao">
                                    <option disabled="disabled" selected="selected">{{$t('lianxi.请选择')}}</option>
                                    <option value="Google搜寻">{{$t('lianxi.Google搜寻')}}</option>
                                    <option value="直接搜寻">{{$t('lianxi.直接搜寻')}}</option>
                                    <option value="新闻报导">{{$t('lianxi.新闻报导')}}</option>
                                    <option value="朋友或同事介绍">{{$t('lianxi.朋友或同事介绍')}}</option>
                                    <option value="展览">{{$t('lianxi.展览')}}</option>
                                    <option value="其他">{{$t('lianxi.其他')}}</option>
                                </select></div>
                        </div>
                        <div class="col-md-12"><label>{{$t('lianxi.text')}}</label><textarea  v-model="Contact_data.Text" rows="6" name="Message"></textarea></div>
                        <div class="col-md-12"><el-button type="primary" class="btn btn--primary type--uppercase" @click="Butfs">{{$t('lianxi.but')}}</el-button></div>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
import { Loading } from 'element-ui'
export default {
  data () {
    return {
      Contact_data: {
        Xml: '',
        Gj: '',
        ChengS: '',
        Gsname: '',
        Name: '',
        Email: '',
        Ipone: '',
        Qdao: '',
        Text: ''
      }
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    async Career () {
      const { data: res } = await this.$http.get('Career')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.Career_data = res.response
    },
    async Butfs () {
      if (this.Contact_data.ChengS === '' || this.Contact_data.Gj === '' || this.Contact_data.Xml === '' || this.Contact_data.Gsname === '' || this.Contact_data.Name === '' || this.Contact_data.Email === '' || this.Contact_data.Ipone === '' || this.Contact_data.Qdao === '' || this.Contact_data.Text === '') return this.$message.error('信息未填写完整')
      const loadingInstance = Loading.service({ text: '拼命加载中', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('Mail', this.Contact_data)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(this.$t('State.Eerr'))
      }
      this.Contact_data.Xml = ''
      this.Contact_data.Gj = ''
      this.Contact_data.ChengS = ''
      this.Contact_data.Gsname = ''
      this.Contact_data.Name = ''
      this.Contact_data.Email = ''
      this.Contact_data.Ipone = ''
      this.Contact_data.Qdao = ''
      this.Contact_data.Text = ''
      loadingInstance.close()
      this.$message.success(this.$t('State.success'))
    }
  }
}
</script>
<style lang="less" scoped>
    footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
    .el-button{
        width: 100%;
        background-color: #7ca2c9 !important;
        border-color: #7ca2c9 !important;
    }
</style>
