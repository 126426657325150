<template>
    <div>
        <section data-overlay="6" class="cover imagebg text-left space--sm">
            <div class="background-image-holder" :style="`background: url(${$FilesImg}${News_data.Backgroundimg}); opacity: 1;`"><img alt="background" src="News_data.Backgroundimg"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7">
                        <h1 v-html="News_data.H1text"></h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5 breadcrumbsSection">
            <div class="container">
                <ol class="breadcrumbs">
                    <li><a href="/#/Home" class="router-link-active">{{this.$t('Pathyem.Home')}}</a></li>
                    <li v-html="News_data.H1text"></li>
                </ol>
            </div>
        </section>
        <section>
            <div class="container">
                <div>
                    <div class="masonry-filter-container d-flex align-items-center"><span>类别</span>
                        <div class="masonry-filter-holder">
                            <el-select v-model="value"  @change="select_menus" placeholder="请选择">
                                <el-option
                                    v-for="item in News_data.Menuns"
                                    :key="item.Id"
                                    :label="item.Menus"
                                    :value="item.Id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <hr>
                    <div class="masonry__container row masonry--active">
                        <div class="masonry__item col-12 col-sm-6 col-md-4 col-lg-4 " v-for="(item,index) in News_list1" :key="index">
                            <article class="feature feature-1"><a
                                    :href="`/#/NewsData/${item.Id}`" class="block"><img
                                        :alt="item.H5text"
                                        :src="`${$FilesImg}${item.Imgsrc}`"
                                        class="w-100"></a>
                                <div class="feature__body boxed boxed--border"><span v-html="item.Datetext"></span>
                                    <h5 v-html="item.H5text"></h5><a
                                        :href="`/#/NewsData/${item.Id}`" class="">浏览</a>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="pagination">
                    <!---->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="queryInfo.pagenum"
                        :page-sizes="[12, 15, 21, 30]"
                        :page-size="queryInfo.pagesize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </section>
        <div>
            <Foorm></Foorm>
        </div>
    </div>
</template>
<script>
import Foorm from '../components/Foorm'
export default {
  data () {
    return {
      News_data: {
        Backgroundimg: '',
        H1text: '',
        Menuns: []
      },
      News_list1: [],
      total: 0,
      queryInfo: {
        Id: 0,
        Pagenum: 1, // 当前的页数
        Pagesize: 12// 当前每页显示多少条
      },
      value: ''
    }
  },
  created () {
    this.News()
    this.News_Menuns()
  },
  mounted () {

  },
  components: {
    Foorm
  },
  methods: {
    async News_Menuns () {
      const { data: res } = await this.$http.get('News_Menuns')
      if (res.status !== 200) return this.$message.error('数据异常')
      this.News_data = res.response
      for (let index = 0; index < res.response.Menuns.length; index++) {
        if (this.queryInfo.Id === 0) {
          this.value = '所有分类'
        } else {
          if (res.response.Menuns[index].Id === parseInt(this.queryInfo.Id)) {
            this.value = res.response.Menuns[index].Menus
            return
          }
        }
      }
    },
    async News () {
      const { data: res } = await this.$http.get('News', { params: this.queryInfo })
      if (res.status !== 200) return this.$message.error('数据异常')
      this.News_list1 = res.response.News_list1
      this.total = res.response.Total
    },
    // 监听pagesize的改变事件
    handleSizeChange (newSize) {
      this.queryInfo.Pagesize = newSize
      this.News()
    },
    // 监听页面值的改变事件
    handleCurrentChange (newPage) {
      this.queryInfo.Pagenum = newPage
      this.News()
    },
    select_menus (data) {
      this.queryInfo.Id = data
      this.News()
      this.News_Menuns()
    }
  }
}
</script>
<style lang="less" scoped>
footer,
    section {
        padding-top: 5.57142857em;
        padding-bottom: 5.57142857em;
    }
</style>
